import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      slidesPerView: 1.25,
      spaceBetween: 15,
      freeMode: true,
      breakpoints: {
        768: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
      },
    })
  }
}
