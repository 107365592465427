require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('css/application.scss')
require.context('../images', true)

import 'alpinejs'
import 'typeface-ubuntu'
import 'typeface-open-sans'
import "@fontsource/red-hat-display"
import 'controllers'
import SweetScroll from 'sweet-scroll'

new SweetScroll()

window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}
