import { Controller } from 'stimulus'
import Swiper, { Navigation, EffectFade } from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {

  static targets= ["container", "prev", "next", "imageContainer", "buttonSlider"]

  connect() {
    const mainSlider = new Swiper(this.containerTarget, {
      slidesPerView: 1,
      loop: true,
      effect: 'fade',
      autoHeight: true,
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      }
    })

    const imageSlider = new Swiper(this.imageContainerTarget, {
      slidesPerView: 1,
      loop: true,
      autoHeight: true,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })

    const buttonSlider = new Swiper( this.buttonSliderTarget, {
      slidesPerView: 1,
      loop: true,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })

    mainSlider.on('slideChange', function() {
      imageSlider.slideTo(mainSlider.activeIndex, 300, true)
      buttonSlider.slideTo(mainSlider.activeIndex, 300, true)
    })
  }
}
