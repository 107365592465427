import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      autoplay: 6000,
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
    })
  }
}
