import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      slidesPerView: 1,
      spaceBetween: 16,
      loop: true,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        }
      }
    })
  }
}
